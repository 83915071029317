/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

@import "scripts/css/bootstrap.min.css";
@import "scripts/css/fontawesome.min.css";
@import "scripts/css/noto-serif.css";
@import '../node_modules/ngx-toastr/toastr.css';
@import "../node_modules/ng-pick-datetime/assets/style/picker.min.css";
@import "scripts/css/customIconStyle.css";
@import "scripts/css/customSwitchDesign.css";

@font-face {
  font-family: face;
  src: url("scripts/css/noto-serif.css");
}

td , th {
  white-space: normal;
  word-wrap: break-word;
  max-width: 200px;
}

thead {
  position: sticky;
  top: 0;
}
/* tfoot {
  position: sticky;
  bottom: 0;
} */

.heading{
  font-size: 3vw;
}

/* Dynamic Form Design  */
/* .colWidth{
  width: 200px;} */

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: 2rem;
}
.custom-day:hover,
.custom-day.focused {
  background-color: #e6e6e6;
}
.weekend {
  background-color: #f0ad4e;
  border-radius: 1rem;
  color: white;
}
.hidden {
  display: none;
}
.ngb-dp-day.disabled {
  color: gray;
}

.wordWrap{
  overflow-wrap: break-word;
}

.dropdown-list .multiselect-dropdown .dropdown-list{ width: max-content; }

.btnSize{
  font-size: small;
}

.msgDesign{
  background-color: rgb(54, 162, 235, 0.6);
  border-color: rgb(54, 162, 235, 0.6);
}

.subjectWithdraw{
  background-color: grey;
  color: grey;
  cursor: not-allowed;
}

.tableFooterHr {
  border-top: 2px solid currentColor;
  margin: -16px 0 12px 0;
}

.fs-22 {
  font-size: 22px;
}

.label-underline {
  text-decoration-line: underline;
}
.custom-card {
  display: inline-block;
  margin-bottom: 7px;
  margin-top:7px;
  background: #d3d3d3;
  box-shadow: 0 .5rem 1rem rgb(0 0 0 / 10%) !important;
  font-size:13px;
}

.custom-card.operator {
  background: none;
  border: none !important;
  box-shadow: none !important;
  color: #8a23e7;
  font-weight: bold;
  padding: 0 !important;
}
.text-danger.invalid-text {
  font-weight: 500;
  margin-bottom: 10px;
}
#expandable {
  height: 115px;
  border: 1px solid #175bad;
  overflow-y: auto;
  padding: 5px;
  box-sizing: border-box; /* Include padding in height and width */
  font-family: Arial, sans-serif; /* Set your desired font */
  position: relative; /* Position for the resize handle */
}

#expandable:focus {
  outline: none; /* Remove the default outline */
}
.resize-handle {
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: se-resize; /* Default cursor for resizing */
}

/* Change cursor when hovering over the resize handle */
.resize-handle:hover {
  cursor: se-resize; /* Resizing cursor */
}
.resize-handle::before,
.resize-handle::after {
    content: '';
    position: absolute;
    background: gray; /* Color of the lines */
}

.resize-handle::before {
  width: 20px; /* Width of the first line */
  height: 1px; /* Height of the first line */
   /* Positioning */
   /* Align left */
   right: -2px;
   bottom: 7px;
  transform: rotate(-45deg); /* Diagonal line */
}

.resize-handle::after {
  width: 11px; /* Width of the second line */
  height: 1px; /* Height of the second line */
   /* Positioning */
   left: -1px;
   bottom: 4px;
  transform: rotate(-45deg); /* Diagonal line */
}

/* .imageDefaultSize{
  width: 500px;
  height: 300px;
} */

/* Radio Button Design */
#radioGroup .wrap {
  display: inline-block;
  /* padding-left: 10px; */
}
#radioGroup label {
  display: block;
  text-align: center;
  /* margin: 0 0.2em; */
}
#radioGroup input[type="radio"] {
  display: block;
  align-items: center;
  margin: 0.5em auto;
}

.optionNameWordWrap{
  word-wrap: break-word;
}
