.slideToggle ::ng-deep .mat-slide-toggle-thumb {
    color: black;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAYCAMAAADEfo0+AAAATlBMVEUAAADWRz3VRj/TRj7TRT7TRD3mTU3URT7URT7URD7TRT/URT7fUEDVRT3TRT3TRj7URT3VVVXTRj7TRj7VRj7URT3URT7YTk7URD7URD0qYCZcAAAAGnRSTlMAMmYduv8K2+aZhskQhcw6+QZjgGKrvQ3z8sqy96YAAAB6SURBVHgBfY/VAYMAFAPTwuHu7D8o7nY/zyV64ff//w6hYQKYhhYsG8d1HWxrSXj4gRT4hEsiIh5NTLQkEtLRpCRLAo42zXLIR0ZbpCo5USqhyjcqEkGtjRoEOgDb6iI9JUooT4kG2lvH544NuP1x+/SuZVB7YFh9pQfYdQjNkhTqDAAAAABJRU5ErkJggg==');
    background-size: 8px 12px;
  }
  
.slideToggle ::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    color: white;
    background-color: rgb(2, 136, 209);
}

.slideToggle ::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(2, 136, 209, 0.38);
}

.slideToggle ::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAQAAAD8x0bcAAAArElEQVR4AYTKAQbCYBzG4X90hQBYEUXH6ALpDIEuMUaBXSgwQGcIhJQABM3wRCZrvvS88MMb3wzMLAzjN0tncLeKNBMPJ1sbF7VppCg9jd41Visjxdz600eH+Eelij47Kfvo0rgp5J0VrproQhE9CvqnvK1M1lYuffKaA9gtDvgVFYAVFeBXJPB/ARAK4FGEALgUrf7PjCLCDBRBU3QVa2BeQ4+3BixQi4F4AABsgsnMW53ZPgAAAABJRU5ErkJggg==');
    background-size: 12px 12px;
}