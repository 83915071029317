@font-face {
    font-family: 'Noto Serif';
    font-style: normal;
    font-weight: 400;
    src: local('Noto Serif'), url('https://fonts.cdnfonts.com/s/12250/NotoSerif-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Noto Serif';
    font-style: italic;
    font-weight: 400;
    src: local('Noto Serif'), url('https://fonts.cdnfonts.com/s/12250/NotoSerif-Italic.woff') format('woff');
}
@font-face {
    font-family: 'Noto Serif';
    font-style: normal;
    font-weight: 700;
    src: local('Noto Serif'), url('https://fonts.cdnfonts.com/s/12250/NotoSerif-Bold.woff') format('woff');
}
@font-face {
    font-family: 'Noto Serif';
    font-style: italic;
    font-weight: 700;
    src: local('Noto Serif'), url('https://fonts.cdnfonts.com/s/12250/NotoSerif-BoldItalic.woff') format('woff');
}


